interface SidebarItem {
  title: string;
  icon?: string;
  path?: string;
  children?: SidebarItem[];
}

export default [
  {
    title: 'Dashboard',
    icon: 'dashboard',
    children: [
      {
        title: '监控台',
        path: '/dashborad/1'
      },
      {
        title: '工作台',
        path: '/dashborad/2'
      }
    ]
  },
  {
    title: '工具集',
    icon: 'tool',
    children: [
      {
        title: '版本开通',
        path: '/utils/1'
      },
      {
        title: '权限管理',
        path: '/utils/2'
      },
      {
        title: '数据恢复',
        path: '/utils/recovery'
      },
      {
        title: '营销相关',
        path: '/utils/marketing'
      }
    ]
  }
] as SidebarItem[];
