import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBase } from '../requests';

@Injectable({ providedIn: 'root' })
export class PassportRequest {
  constructor(private http: HttpBase) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login(data: { memberName: string; password: string }): Observable<any> {
    const { memberName, password } = data;
    return this.http.post('/login', {
      memberName,
      password
    });
  }
}
