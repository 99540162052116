/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface RequestOptions {
  body?: any;
  headers?: HttpHeaders;
  params?: HttpParams | { [param: string]: string | string[] };
}

const API_PREFIX = '/apidev';

@Injectable({ providedIn: 'root' })
export class HttpBase {
  constructor(private http: HttpClient) {}

  post<T>(url: string, body: any): Observable<T> {
    return this.request('POST', url, { body });
  }

  get<T>(url: string, params?: any): Observable<T> {
    return this.request('GET', url, { params });
  }

  delete<T>(url, params?: any): Observable<T> {
    return this.request('DELETE', url, { params });
  }

  private request<T>(method: string, url: string, options: RequestOptions): Observable<T> {
    const parsedUrl = `${API_PREFIX}${url}`;
    return this.http.request<T>(method, parsedUrl, options);
  }
}
