import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TokenGuard } from '@fe/core/guards';
import { BasicLayoutComponent } from '../layout/basic-layout.component';
import { PassportComponent } from './passport/passport.component';

const routes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [TokenGuard],
    children: [
      { path: '', redirectTo: 'dashboard/analysis', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'utils', loadChildren: () => import('./utils/utils.module').then(m => m.UtilsModule) }
    ]
  },
  {
    path: 'passport',
    component: PassportComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutesRoutingModule { }
