import { Component, OnInit } from '@angular/core';
import sideBarConfig from '../config/sidebar.config';

@Component({
  selector: 'my-basic-layout',
  templateUrl: 'basic-layout.component.html',
  styleUrls: ['basic-layout.component.less'],
})
export class BasicLayoutComponent implements OnInit {
  confifg = sideBarConfig;
  isCollapsed = false;

  constructor() {}

  ngOnInit() {}
}
