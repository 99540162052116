import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.get();
    if (token) {
      const secureReq  = req.clone({
        setHeaders: { token }
      });
      return next.handle(secureReq);
    } else {
      return next.handle(req);
    }
  }

  constructor (private tokenService: TokenService) {}
}
