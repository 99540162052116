import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

const TOKEN_NAME = 'my_token';

@Injectable({providedIn: 'root'})
export class TokenService {
  set(value: string): void {
    this.cookieService.set(TOKEN_NAME, value);
  }

  get(): string {
    return this.cookieService.get(TOKEN_NAME);
  }

  constructor(private cookieService: CookieService) {}
}
