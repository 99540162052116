<div class="passport-container">
  <div class="passport-form-content">
    <div class="passport-form-content-top">
      <div class="passport-form-content-header">
        <img src="/assets/logo.png" alt="logo">
        <span class="passport-form-content-title">QingFlow Maya 2</span>
      </div>
      <div class="passport-form-content-desc">
        轻流后台管理系统 2.0 -- Angular Version
      </div>
    </div>
    <div class="passport-form-content-body">
      <form nz-form class="login-form" [formGroup]="form">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your username!">
            <nz-input-group nzPrefixIcon="user" nzSize="large">
              <input type="text" nz-input formControlName="memberName" placeholder="用户名" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <nz-input-group nzPrefixIcon="lock" nzSize="large">
              <input type="password" nz-input formControlName="password" placeholder="密码" />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <div nz-row class="login-form-margin">
          <div nz-col [nzSpan]="12">
            <label nz-checkbox>
              <span>自动登录</span>
            </label>
          </div>
          <div nz-col [nzSpan]="12">
            <a class="login-form-forgot">忘记密码</a>
          </div>
        </div>
        <button
          nz-button
          class="login-form-button login-form-margin"
          nzSize="large"
          [nzType]="'primary'"
          [nzLoading]="isLoading"
          (click)="login()"
        >登 录</button>
        或 <a> 注册账户 </a>
      </form>
    </div>
  </div>
</div>
