import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PassportComponent } from './passport.component';

@NgModule({
  imports: [ReactiveFormsModule, NzButtonModule, NzInputModule, NzFormModule, NzIconModule, NzCheckboxModule],
  declarations: [PassportComponent],
  exports: [PassportComponent]
})
export class PassportModule { }
