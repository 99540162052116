import { NgModule } from '@angular/core';
import { PassportModule } from './passport/passport.module';
import { RoutesRoutingModule } from './routes-routing.module';


@NgModule({
  imports: [
    RoutesRoutingModule,
    PassportModule
  ]
})
export class RoutesModule {}
