<nz-layout style="min-height: 100%;">
  <nz-sider nzCollapsible>
    <div class="logo">
      <a>
        <img class="logo-img" src="/assets/logo.png" alt="logo">
        <span class="logo-title">Maya 2</span>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline">
      <ng-container *ngFor="let item of confifg">
        <li nz-submenu [nzTitle]="item.title" [nzIcon]="item.icon">
          <ul>
            <li
              *ngFor="let subItem of item.children"
              style="outline: none;"
              nz-menu-item
              nzMatchRouter
              [routerLink]="subItem.path"
            >
              {{ subItem.title }}
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
    </nz-header>
    <nz-content style="margin-top: 80px;">
      <router-outlet></router-outlet>
      <!-- <nz-breadcrumb>
        <nz-breadcrumb-item>User</nz-breadcrumb-item>
        <nz-breadcrumb-item>Bill</nz-breadcrumb-item>
      </nz-breadcrumb> -->
    </nz-content>
    <nz-footer>Maya2 ©2021 By Qingflow</nz-footer>
  </nz-layout>
</nz-layout>
