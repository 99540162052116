import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpBase } from '../requests';
import { INewFounction } from '../types';

@Injectable({ providedIn: 'root' })
export class UtilsRequest {
  constructor(private http: HttpBase) {}

  getDeletedApplyCount(data: { appKey: string; startTime: number; endTime: number }): Observable<{ deletedApplyCount: number }> {
    return this.http.get('/apply/deletedApply', data);
  }

  revertDeletedApply(data: { appKey: string; startTime: number; endTime: number }): Observable<{ applyIds: number[] }> {
    return this.http.post('/apply/deletedApply', data);
  }

  getNewFunctionIntro(): Observable<INewFounction[]> {
    return this.http.get<{ newFunctions: INewFounction[] }>('/newFunctionIntro')
      .pipe(map(data => data.newFunctions));
  }

  addNewFunctionIntro(data: INewFounction): Observable<INewFounction> {
    return this.http.post('/newFunctionIntro', data);
  }

  updateNewFunctionIntro(data: INewFounction): Observable<INewFounction> {
    return this.http.post(`/newFunctionIntro/${data.id}`, data);
  }

  removeNewFunctionIntro(newFunctionIntroId: number): Observable<{ newFunctionIntroId: number }> {
    return this.http.delete(`/newFunctionIntro/${newFunctionIntroId}`);
  }
}
