import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TokenService } from '@fe/core/services';
import { PassportRequest } from '@maya2/requests';

@Component({
  selector: 'my-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.less']
})
export class PassportComponent implements OnInit {
  form: FormGroup;
  isLoading = false;

  async login(): Promise<void> {
    this.isLoading = true;
    try {
      const res = await this.request.login(this.form.value).toPromise();
      this.tokenService.set(res.token);
      await this.router.navigateByUrl('/utils/recovery');
    } catch(_error) {
      this.isLoading = false;
    }
  }

  constructor(
    private request: PassportRequest,
    private tokenService: TokenService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      memberName: null,
      password: null
    });
  }

  test(): void {
    console.log(this.form.value);
  }
}
