import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule, Provider } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { CookieService } from 'ngx-cookie-service';
import { TokenInterceptor } from '@fe/core/interceptors';
import { RoutesModule } from './routes/routes.module';
import { LayoutModule } from './layout/layout.module';
import { AppComponent } from './app.component';

registerLocaleData(zh);

const INTERCEPTOR_PROVIDERS: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    RoutesModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule // 页面基本布局
  ],
  declarations: [AppComponent],
  providers: [
    CookieService,
    ...INTERCEPTOR_PROVIDERS
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
