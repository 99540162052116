import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBase } from '../requests';
import { IOSSData } from '../types';

@Injectable({ providedIn: 'root' })
export class CommonRequest {
  constructor(private http: HttpBase) {}

  getOssData(fileName: string, size?: number, contentType?: string): Observable<IOSSData> {
    const upload = {
      fileName,
      pathId: 80,
      uploadMark: 'maya',
      timestamp: new Date().getTime(),
      fileSize: size,
      bucketType: 'public',
      contentType
    };
    return this.http.post('/upload/url', upload);
  }
}
